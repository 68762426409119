import Vue from "vue";
import { i18n } from "./plugins/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import Vuelidate from "vuelidate";
import VeeValidate from 'vee-validate';
import VueTelInput from "vue-tel-input";
import duplicateWindow from "@/plugins/duplicateWindow";
import "vue-tel-input/dist/vue-tel-input.css";
import "./SCSS/main.scss";
import "vue-loading-overlay/dist/vue-loading.css";
import "./filters/index";
import "./directives/index";
import EmojiPickerPlugin from "vue-emoji-picker";
import "@/plugins/online/themes/offline-theme-chrome.css";
import "@/plugins/online/themes/offline-language-english.css";
import consolere from "console-remote-client";
import * as VueGoogleMaps from 'vue2-google-maps';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import ImageUploader from 'vue-image-upload-resize';
import "./assets/css/app.css";
import "./assets/css/responsive.css";

consolere.connect({
  server: "https://console.re", // optional, default: https://console.re
  channel: "redlineusedautoparts", // required
  redirectDefaultConsoleToRemote: true, // optional, default: false
  disableDefaultConsoleOutput: false, // optional, default: false
});

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: 'places',
  },
});

// Global Components

Vue.component("vue-tel-input", VueTelInput.VueTelInput);
Vue.component("EmojiPicker", EmojiPickerPlugin);
Vue.component("DateRangePicker", DateRangePicker);

Vue.use(VeeValidate, {
  errorBagName: 'vErrors'
});
Vue.use(Vuelidate);
Vue.use(EmojiPickerPlugin);
Vue.use(ImageUploader);

// const eventsHub = new Vue();
// Vue.use(IdleVue, { eventEmitter: eventsHub, idleTime: 300000 });

// Check for duplicate tabs
duplicateWindow();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
